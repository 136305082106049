/* Certifications.css */

.certifications-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  }
  
  .certification-card {

    font-size: 18px;
    margin: 10px;
    padding: 15px;
    opacity: 0.93;
    text-align: center;
    /* alighn the text in ceter vertically */
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137);
    overflow: hidden;
    transition: all 0.4s ease 0s;
    cursor: pointer;
    position: relative;
    justify-content: center;
    align-items: center;
    background-color: rgpa(0,0,0,0.1);
    border:2px solid rgba(197, 115, 230, 0.883); /* Border color */
  }
  
  .certification-card:hover {
    transform: scale(1.1);
    border: 2px solid rgba(197, 115, 230, 0.883);
  }
  
  .certification-link {
    display: block;
    margin-top: 15px;
    color: #fdfbfb;
    text-decoration: none;
    opacity: 0.5;
  }
  
  .certification-card:hover .certification-link {
    color: rgba(255, 255, 255, 0.883);
    /* underline */
    text-decoration: underline;
    opacity: 1;
  }
  
  
  