/* RelevantCoursework.css */

.skills-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.Analysis {
  border: 1.7px solid rgba(200, 137, 230, 0.637);
}

.GIS {
  border: 1.7px solid rgba(0, 173, 239, 0.637);
}

.Design {
  border: 1.7px solid rgba(0, 128, 0, 0.637);
}

.Programming {
  border: 1.7px solid rgba(255, 185, 0, 0.637);
}

.tech-box {
  font-size: 20px;
  font-weight: bold;
  margin: 15px;
  padding: 25px;
  opacity: 0.93;
  text-align: center;
  border-radius: 5px;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137);
  overflow: hidden;
  transition: all 0.4s ease 0s;
  cursor: pointer;
  position: relative;
  /* align the text in center */
  display: flex;
  justify-content: center;
  align-items: center;
  
  
}

.tech-box:hover {
  transform: scale(1.1);
  border: 2.2px solid rgba(197, 115, 230, 0.883);
}

.category-text {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  color: white;
  opacity: 0;
}

.tech-box:hover .category-text {
  opacity: 1; /* Make category text visible on hover */
}
